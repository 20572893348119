<template>
    <div class="platform-ratings">
        <Ratings class="platform-ratings__stars" :value="userReviews.averageScore" />
        <div class="platform-ratings__title">
            {{ $t('sections.platform_ratings.title', { rating: userReviews.averageScore }) }}
        </div>
        <div class="platform-ratings__description">
            {{ $t('sections.platform_ratings.description', { count: userReviews.displayedReviewCountRounded }) }}
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Ratings from '~/components/Ratings.vue';
import { actionTypes as configActions } from '~/store/config';

export default {
    components: { Ratings },
    computed: {
        ...mapGetters(['userReviews']),
    },
    mounted() {
        // In some weird cases (possibly only on local environment?), the userReviews are not already fetched here.
        // Fetching them again to make sure they are not shown as 0.
        if (this.userReviews.averageScore === 0) {
            this.$store.dispatch(configActions.FETCH_USER_REVIEWS, this.$i18n);
        }
    },
};
</script>

<style lang="scss">
@import '@/sass/_variables.scss';

.platform-ratings {
    text-align: center;
    color: $white;

    &__stars {
        justify-content: center;
        font-size: 22px;

        .ratings__icon.svg-icon {
            color: $secondary-color;
        }
    }

    &__title {
        @include font-size(18px);
        font-weight: 600;
        line-height: 1.8em;
    }

    &__description {
        @include font-size(14px);
    }
}
</style>
