<template>
    <div class="interview-blurb-section section">
        <h2 class="section__heading">
            <template v-if="type === 'renter'">
                {{ $t('sections.interview_blurb.user_story_label') }}
            </template>
            <template v-else>{{ $t('sections.interview_blurb.onwer_story_label') }}</template>
        </h2>
        <div class="section__content interview-blurb">
            <template v-if="type === 'renter'">
                <img
                    v-lazy-load-image="require('assets/images/renter-interview.png')"
                    width="580"
                    height="385"
                    class="interview-blurb__image"
                    :alt="$t('sections.interview_blurb.renter_alt')"
                />
                <div class="interview-blurb__content">
                    <p class="interview-blurb__text">
                        {{ $t('sections.interview_blurb.renter_text') }}
                    </p>
                    <LocalizedLink
                        :to="renterInterviewRoute"
                        class="interview-blurb__button button button--primary button--full-width"
                    >
                        {{ $t('sections.interview_blurb.renter_button') }}
                    </LocalizedLink>
                </div>
            </template>
            <template v-else>
                <img
                    v-lazy-load-image="require('assets/images/owner-interview.png')"
                    width="380"
                    height="252"
                    class="interview-blurb__image"
                    :alt="$t('sections.interview_blurb.owner_alt')"
                />
                <div class="interview-blurb__content">
                    <p class="interview-blurb__text">
                        {{ $t('sections.interview_blurb.owner_text') }}
                    </p>
                    <LocalizedLink
                        :to="ownerInterviewRoute"
                        class="interview-blurb__button button button--primary button--full-width"
                    >
                        {{ $t('sections.interview_blurb.owner_button') }}
                    </LocalizedLink>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
export default {
    name: 'InterviewBlurb',
    props: {
        type: {
            type: String,
            default: 'renter',
        },
    },
    computed: {
        renterInterviewRoute() {
            return this.localePath({
                name: 'magazine',
            });
        },
        ownerInterviewRoute() {
            return this.localePath({
                name: 'magazine-slug',
                params: {
                    slug: this.$t('magazine.article_slugs.owner_interview'),
                },
            });
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/sass/_variables.scss';
.interview-blurb-section.section {
    padding: 50px 0;
    background-color: $blue;

    @include md {
        padding: 40px 0;
    }

    .interview-blurb {
        padding: 10px;

        @include md {
            display: flex;
            max-width: 800px;
            align-items: center;
        }

        &__image {
            width: 100%;
            max-width: 100%;
            height: auto;
            border-radius: $default-border-radius;

            @include md {
                max-width: calc(50% - 20px);
                margin-right: 20px;
            }
        }

        &__content {
            @include md {
                margin-left: 20px;
            }
        }

        &__text {
            color: $primary;
            margin: 15px 0;
            @include font-size(16px);

            @include md {
                margin-top: 0;
            }
        }

        &__button {
            box-sizing: border-box;
        }
    }
}
</style>
