<template>
    <section class="main-usps section section--floating">
        <ol class="main-usps__items wrap">
            <li class="usp">
                <img
                    :alt="$t('icon_img_alt.insurance')"
                    src="~/assets/images/big-icons/insurance.svg"
                    class="usp__icon"
                />
                <h3 class="usp__heading">
                    {{ $t('sections.main_usps.insurance') }}
                </h3>
            </li>
            <li class="usp">
                <img
                    :alt="$t('icon_img_alt.road_assistance')"
                    src="~/assets/images/big-icons/tow.svg"
                    class="usp__icon"
                />
                <h3 class="usp__heading">
                    {{ $t('sections.main_usps.road_assistance') }}
                </h3>
            </li>
            <li v-if="isForOwners" class="usp">
                <img
                    :alt="$t('icon_img_alt.verified_users')"
                    src="~/assets/images/big-icons/report.svg"
                    class="usp__icon"
                />
                <h3 class="usp__heading">
                    {{ $t('sections.main_usps.verified_users') }}
                </h3>
            </li>
            <li v-if="isForRenters" class="usp">
                <img
                    :alt="$t('icon_img_alt.secure_payments')"
                    src="~/assets/images/big-icons/credit-card.svg"
                    class="usp__icon"
                />
                <h3 class="usp__heading">
                    {{ $t('sections.main_usps.secure_payments') }}
                </h3>
            </li>
            <li class="usp">
                <img
                    :alt="$t('icon_img_alt.phone_support')"
                    src="~/assets/images/big-icons/seller.svg"
                    class="usp__icon"
                />
                <h3 class="usp__heading">
                    {{ $t('sections.main_usps.phone_support') }}
                </h3>
            </li>
        </ol>
    </section>
</template>

<script>
export default {
    name: 'MainUsps',
    props: {
        // 'renters' or 'owners'
        for: {
            type: String,
            default: 'renters',
        },
    },
    computed: {
        isForOwners() {
            return this.for === 'owners';
        },
        isForRenters() {
            return this.for === 'renters';
        },
    },
};
</script>
