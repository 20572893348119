<template>
    <div class="contact section wrap">
        <h2 class="contact__heading">{{ $t('sections.contact.heading') }}</h2>
        <i18n tag="p" path="sections.contact.text" class="contact__intro">
            <template #faqLink>
                <a target="_blank" :href="$t('links.faq')">
                    {{ $t('sections.contact.faq_heading') }}
                </a>
            </template>
            <template #openingHoursLink>
                <a target="_blank" :href="$t('links.mycamper_opening_hours')">
                    {{ $t('support.opening_hours') }}
                </a>
            </template>
        </i18n>
        <div class="contact__boxes">
            <div class="contact-box">
                <h3 class="contact-box__header">
                    {{ $t('sections.contact.phone_heading') }}
                    <svg-icon class="contact-box__icon" name="phone" />
                </h3>
                <a :href="'tel:' + supportPhoneConfig.supportPhoneNormalized" class="contact-box__link">
                    {{ supportPhoneConfig.supportPhone }}
                </a>
            </div>
            <div class="contact-box">
                <h3 class="contact-box__header">
                    {{ $t('sections.contact.email_heading') }}
                    <svg-icon class="contact-box__icon" name="mail" />
                </h3>
                <a :href="'mailto:' + $store.getters.supportEmail" class="contact-box__link">{{
                    $store.getters.supportEmail
                }}</a>
            </div>
            <div class="contact-box">
                <h3 class="contact-box__header">
                    {{ $t('sections.contact.faq_heading') }}
                </h3>
                <LocalizedLink target="_blank" :href="$t('links.faq')" class="contact-box__link">
                    {{ $t('sections.contact.faq_link') }}
                </LocalizedLink>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Contact',
    computed: {
        ...mapGetters(['supportPhonePerLocale', 'supportPhoneNormalizedPerLocale']),
        supportPhoneConfig() {
            return {
                supportPhone: this.supportPhonePerLocale(this.$i18n.locale),
                supportPhoneNormalized: this.supportPhoneNormalizedPerLocale(this.$i18n.locale),
            };
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/_variables.scss';

.contact {
    &__heading {
        @extend %heading-2;
        margin: auto auto 24px;
        text-align: center;
    }

    &__intro {
        @extend %description-text;
        text-align: center;

        a {
            display: inline-block;
        }

        &:first-of-type {
            margin-bottom: 0;
        }
    }

    &__boxes {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 24px auto 0;

        @include md {
            flex-direction: row;
        }
    }

    .contact-box {
        box-sizing: border-box;
        @extend %card;
        border-color: $cream-white;
        padding: 35px;
        text-align: center;
        margin-bottom: 30px;
        width: 100%;
        max-width: 390px;
        min-height: 133px;

        @include md {
            flex-basis: 30%;
        }

        &__header {
            @extend %heading-3;
            margin-bottom: 15px;
        }

        &__icon.svg-icon {
            width: 24px;
            height: 24px;
            color: $primary;
            margin-top: -5px;
        }
    }
}
</style>
