<template>
    <div class="skewed-img__wrapper">
        <div class="skewed-img__mask">
            <div class="skewed-img__img" :style="backgroundImageStyle" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'SkewedImage',
    props: {
        src: {
            type: String,
            required: true,
        },
        srcSmall: {
            type: String,
            default: null,
        },
        backgroundPosition: {
            type: String,
            default: 'left center',
        },
        preloadImages: {
            type: Boolean,
            default: false,
        },
    },
    head() {
        if (!this.preloadImages) {
            return;
        }

        // The large image is always preloaded, small image is optional
        const large = {
            hid: 'skewed-image-large',
            rel: 'preload',
            as: 'image',
            href: this.src,
            fetchpriority: 'high',
        };

        if (this.srcSmall) {
            large.media = '(min-width: 992px)';

            return {
                link: [
                    {
                        hid: 'skewed-image-small',
                        rel: 'preload',
                        as: 'image',
                        href: this.srcSmall,
                        fetchpriority: 'high',
                        media: '(max-width: 991px)',
                    },
                    large,
                ],
            };
        }

        return {
            link: [large],
        };
    },
    computed: {
        ...mapGetters(['isLargeScreen']),
        backgroundImageStyle() {
            let src;
            let gradient;

            if (this.isLargeScreen) {
                src = this.src;
                gradient = `linear-gradient(75deg, rgba(0, 0, 0, 0.40) 26.18%, rgba(0, 0, 0, 0.00) 43.28%)`;
            } else {
                src = this.srcSmall ? this.srcSmall : this.src;
                gradient = `linear-gradient(0deg, rgba(0, 0, 0, 0.50) 25.79%, rgba(255, 255, 255, 0.00) 43.13%)`;
            }

            return {
                backgroundImage: `${gradient}, url(${src})`,
                backgroundPosition: this.backgroundPosition,
            };
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/sass/_variables.scss';
$lg-border-radius: 40px;

.skewed-img {
    &__wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;
        border-radius: 0 0 $lg-border-radius $lg-border-radius;

        @include md {
            border-radius: $lg-border-radius;
        }
    }

    &__mask {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }

    &__img {
        background-size: cover;
        background-color: lightgray;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    @include md {
        &__mask {
            margin: 0 0 0 -25px;
            -webkit-transform: skew($skewed-img-angle);
            -moz-transform: skew($skewed-img-angle);
            -o-transform: skew($skewed-img-angle);
            transform: skew($skewed-img-angle);
            overflow: hidden;
            border-radius: $lg-border-radius;
        }

        &__img {
            top: 0;
            left: 0;
            right: -25px;
            bottom: 0;
            -webkit-transform: skew(-$skewed-img-angle);
            -moz-transform: skew(-$skewed-img-angle);
            -o-transform: skew(-$skewed-img-angle);
            transform: skew(-$skewed-img-angle);
        }
    }
}
</style>
