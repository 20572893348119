<template>
    <section class="section press section--floating">
        <div class="press__containers">
            <div class="press__header">{{ $t('sections.press.partners_heading') }}</div>
            <ul class="press__items">
                <li v-for="(partner, i) in partners" :key="i" class="press__item--partner press__item">
                    <img :src="partner.src" :alt="partner.alt" :width="partner.width" :height="partner.height" />
                </li>
            </ul>
        </div>
        <div v-if="press.length" class="divider" />
        <div v-if="press.length" class="press__containers">
            <div class="press__header">{{ $t('sections.press.press_heading') }}</div>
            <ul class="press__items">
                <li v-for="(item, i) in press" :key="i" class="press__item">
                    <img :src="item.src" :alt="item.alt" :width="item.width" :height="item.height" />
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
const images = {
    omocom: { src: require('~/assets/images/press/omocom.png'), alt: 'Omocom', width: '90', height: '25' },
    acamp: { src: require('~/assets/images/press/acamp.svg'), alt: 'Acamp', width: '90', height: '27' },
    caravanClub: {
        src: require('~/assets/images/press/caravan-club.png'),
        alt: 'Caravan Club',
        width: '61',
        height: '45',
    },
    norskBobilforening: {
        src: require('~/assets/images/press/norskbobilforening.png'),
        alt: 'Norsk Bobilforening',
        width: '65',
        height: '40',
    },
    baloise: { src: require('~/assets/images/press/baloise.png'), alt: 'Baloise', width: '103', height: '21' },
    myClimate: { src: require('~/assets/images/press/myclimate.png'), alt: 'My Climate', width: '83', height: '25' },
    aftonbladet: {
        src: require('~/assets/images/press/aftonbladet.svg'),
        alt: 'Aftonbladet Torsdag Campingspecial',
        width: '88',
        height: '14',
    },
    dagensIndustri: {
        src: require('~/assets/images/press/dagens-industri.png'),
        alt: 'Dagens Industri',
        width: '91',
        height: '20',
    },
    hoc: { src: require('~/assets/images/press/hoc.png'), alt: 'Husvagn Camping', width: '66', height: '41' },
    reiselivsavisa: {
        src: require('~/assets/images/press/reiselivsavisa.png'),
        alt: 'Reiselivsavisa',
        width: '98',
        height: '24',
    },
    vimenn: { src: require('~/assets/images/press/vimenn.png'), alt: 'Vi Menn Bobil', width: '46', height: '26' },
    kauppalehti: {
        src: require('~/assets/images/press/kauppalehti.png'),
        alt: 'Kauppalehti',
        width: '96',
        height: '21',
    },
    caravanLehti: {
        src: require('~/assets/images/press/caravan-lehti.png'),
        alt: 'Caravan-lehti',
        width: '79',
        height: '18',
    },
    iltaSanomat: {
        src: require('~/assets/images/press/ilta-sanomat.png'),
        alt: 'Ilta-Sanomat',
        width: '91',
        height: '19',
    },
    blick: { src: require('~/assets/images/press/blick.png'), alt: 'Blick', width: '68', height: '29' },
    zwanzigMinuten: {
        src: require('~/assets/images/press/20-minuten.png'),
        alt: '20 Minuten',
        width: '35',
        height: '46',
    },
    srf: { src: require('~/assets/images/press/srf.png'), alt: 'SRF', width: '33', height: '22' },
};

const partnersData = {
    se: [images.omocom, images.acamp, images.caravanClub],
    no: [images.omocom, images.acamp, images.norskBobilforening],
    fi: [images.omocom, images.acamp],
    'fi-sv': [images.omocom, images.acamp],
    dk: [images.omocom, images.acamp],
    ch: [images.baloise, images.myClimate],
    de: [images.baloise, images.omocom],
    en: [images.baloise, images.omocom],
};

const pressData = {
    se: [images.aftonbladet, images.dagensIndustri, images.hoc],
    no: [images.reiselivsavisa, images.vimenn],
    fi: [images.kauppalehti, images.caravanLehti, images.iltaSanomat],
    'fi-sv': [images.kauppalehti, images.caravanLehti, images.iltaSanomat],
    ch: [images.blick, images.zwanzigMinuten, images.srf],
    de: [images.blick, images.zwanzigMinuten, images.srf],
    dk: [],
    en: [],
};

export default {
    name: 'Press',
    computed: {
        partners() {
            return partnersData[this.$i18n.locale] || partnersData.en;
        },
        press() {
            return pressData[this.$i18n.locale] || pressData.en;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/sass/_variables.scss';

.press {
    padding: 16px 0;
    background: $cream-white;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include sm {
        flex-wrap: wrap;
        flex-direction: row;
    }

    @include md {
        &__containers:first-of-type {
            padding-right: 50px;
        }
        &__containers:last-of-type {
            padding-left: 50px;
        }
    }

    @include xl {
        &__containers:first-of-type {
            padding-right: 100px;
        }
        &__containers:last-of-type {
            padding-left: 100px;
        }
    }

    &__header {
        margin-bottom: 16px;
        @include font-size(18px);
    }

    &__items {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;

        @include sm {
            flex-direction: row;
        }
    }

    &__item {
        padding: 0 12px;
    }

    .divider {
        border: 1px solid $cream-white-dark;
        margin: 16px 20px;

        @include sm {
            display: none;
        }
    }
}
</style>
