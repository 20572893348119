<template>
    <div class="ratings">
        <svg-icon v-for="num in [0, 1, 2, 3, 4]" :key="num" :name="getIconName(num)" class="ratings__icon" />
        <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
        <span v-if="numberOfVotes !== null" class="ratings__votes">({{ numberOfVotes }})</span>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            default: 0,
        },
        numberOfVotes: {
            type: Number,
            default: null,
        },
    },
    methods: {
        getIconName(num) {
            if (this.value >= num + 0.95) {
                return 'star';
            }

            if (this.value >= num + 0.75) {
                return 'star_almost_full';
            }

            if (this.value >= num + 0.25) {
                return 'star_half';
            }

            return 'star_border';
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/_variables.scss';

.ratings {
    display: flex;
    align-items: center;

    &__icon.svg-icon {
        color: $primary;
        width: 1.3em;
        height: 1.3em;
        vertical-align: middle;
    }

    &__votes {
        color: $gray-dark;
        display: inline-block;
        vertical-align: middle;
    }
}
</style>
